import MapComponent from './components/Map';
import {CardGroup, Container, Row, Col, Card, CardBody, CardText, CardTitle, CardSubtitle, Button, UncontrolledCarousel } from 'reactstrap';
import {useState} from 'react';
import './App.css'
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  const [imgData, setImgData] = useState(null);
  const[markerHandler, setMarkerHandler] = useState(null);
  const[popupHandler, setPopupHandler] = useState(null);

  return (
    <Container>
      <Row style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <Col>
          <MapComponent
            imgDataCallback={(data)=> {
              console.log(data)
              setImgData(data)
            }}
            markerClickHandler={handler => {
              console.log(handler)
              setMarkerHandler(()=> handler);
            }}
            popupCloseHandler={handler => {
              console.log(handler)
              setPopupHandler(()=> handler);
            }}
          />
        </Col>
        {/* {imgData && <div className="mapboxgl-ctrl-bottom-left">
              <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact" style={{marginLeft: '40px'}}>
                <ImgCarousel
                  items={imgData.map((data,index)=> ({
                    altText: data.name,
                    caption: data.name,
                    key: index,
                    src: `data:image/jpeg;base64,${data.imgBase64}`
                  }))}
                />
              </div>
          </div>} */}
      </Row>
      <Row>
        {/* {imgData && (
          <ImgCarousel
            items={imgData.map((data,index)=> ({
              altText: data.name,
              caption: data.name,
              key: index,
              src: `data:image/jpeg;base64,${data.imgBase64}`
            }))}
          />
        )} */}
          {/* {imgData && imgData.map((data, index) => (
            <Col xs="4">
              <Card>
                <img
                  alt={data.name}
                  src={`data:image/jpeg;base64,${data.imgBase64}`}
                />
                <CardBody>
                  <CardTitle tag="h5">
                    {data.name}
                  </CardTitle>
                  <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                  >
                    Card subtitle
                  </CardSubtitle>
                  <CardText>
                    Some quick example text to build on the card title and make up the bulk of the card‘s content.
                  </CardText>
                  {markerHandler && <Button onClick={()=> markerHandler(data)}>
                    See Location
                  </Button>}
                </CardBody>
              </Card>
            </Col>
          ))} */}
      </Row>
    </Container>
  );
}

export default App;