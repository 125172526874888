import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

function ImgCarousel({items, transitionCallback, markerSelectedCallback, endOfCarouselReachedHandler}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [animating, setAnimating] = useState(false);
  const imgDataByIndex = {};
  const imgIndexByName = {};

  console.log(items)

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    // setPreviousIndex(activeIndex);
    if (nextIndex === 0) {
        endOfCarouselReachedHandler(prev => ++prev)
    }

    setActiveIndex(nextIndex);
    // transitionCallback(imgDataByIndex[nextIndex], imgDataByIndex[activeIndex]);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    // setPreviousIndex(activeIndex);
    setActiveIndex(nextIndex);
    // transitionCallback(imgDataByIndex[nextIndex], imgDataByIndex[activeIndex]);
  };

  markerSelectedCallback((imgData) => {
    const activeIndex = imgIndexByName[imgData.name];
    setActiveIndex(activeIndex);
  });

  const slides = items && items.map((item, index) => {
    imgDataByIndex[index] = item;
    imgIndexByName[item.name] = index;

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        className="custom-slide"
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          className="d-block fs-md-2 fs-6"
          captionText={<button className="btn btn-primary" type="button" style={{color: "rgb(63, 177, 206)", "background-color": "#00000000", "border-color": "#00000000"}} onClick={() => {
            const currentData = imgDataByIndex[activeIndex];
            const previousData = imgDataByIndex[previousIndex];

            setPreviousIndex(activeIndex);
            transitionCallback(currentData, previousData);
          }}><i class="bi bi-geo-alt-fill fs-md-2 fs-6"></i><span class="fs-md-2 fs-6">See Location</span></button>}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return ((items &&
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={null}
      fade={true}
    >
      {items && slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )|| <div></div>);
}

export default ImgCarousel;