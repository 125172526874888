import {useState, useEffect, useRef, useCallback} from 'react';
import Map,{Popup, Marker, FullscreenControl} from 'react-map-gl';
import ImgCarousel from './ImgCarousel';
import { isMobile } from 'react-device-detect';
import ImageViewer from "react-simple-image-viewer";

const accessToken = 'pk.eyJ1IjoiZ2FycmV0dGFtaWRvbiIsImEiOiJjbTIwaXdvOGQwYTgzMmlwejRqa2c3Mzl3In0.PjRNQe3hsTKMXoiw3cCXaw';
const uri = '/.netlify/functions/photomapper';
const mapStyles = ['mapbox://styles/garrettamidon/cm20j7ugr003601r4eq1j5xrw', 'mapbox://styles/garrettamidon/cm21yi60y007e01om0nm646r6']

export default function MapComponent({imgDataCallback, markerClickHandler, popupCloseHandler}) {
    const [imgData, setImgData] = useState([]);
    const [viewState, setViewState] = useState({
        longitude: 12.7,
        latitude: 65.1,
        zoom: 3.5
    });

    const [originalViewImage, setOriginalViewImage] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const [previousState, setPreviousState] = useState({});
    let imgCarouselSwitch;

    const [popupOpen, setPopupOpen] = useState({});

    const [dataSetIndex, setDataSetIndex] = useState(0);

    const mapRef = useRef();

    function handleMarkerClick(imgData, jumpInsteadOfFly, skipCarouselUpdate) {
        if (!imgData || !imgData.name) {
            return;
        }

        if (!skipCarouselUpdate){
            imgCarouselSwitch(imgData);
        }

        setPopupOpen((prevPopupOpen = {}) => (
            {...prevPopupOpen, [imgData.name]: true }
        ));

        const currentMap = mapRef?.current;
        const zoomToSet = 14;
        const currentZoom = currentMap.getZoom();
        const {lng, lat} = currentMap.getCenter();

        setPreviousState({
            center: [lng, lat],
            zoom: currentZoom
        });

        const settings = {
            center: [imgData.long, imgData.lat + (.03/Math.max(currentZoom, zoomToSet))],
            speed: .75,
            curve: 1.5,
        }

        if (lng + .001 > imgData.long || lng - .001 < imgData.long || lat + .001 > imgData.lat || lat - .001 < imgData.lat){
            if (currentZoom - zoomToSet < 0) {
                settings.zoom = zoomToSet;
            }

            if (jumpInsteadOfFly) {
                mapRef?.current.jumpTo(settings);
            } else {
                mapRef?.current.flyTo(settings);
            }
        }
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setOriginalViewImage(index);
        setIsViewerOpen(true);
      }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    function handlePopupClose(imgData = {}) {
        setPopupOpen((prevPopupOpen = {}) => (
            {...prevPopupOpen, [imgData.name]: false }
        ));
    }

    useEffect(() => {
        fetch(uri + '?index=' +  dataSetIndex).then(response => response.json()).then(data => {
            const combinedData = [
                ...data,
            ]
            setImgData(combinedData);
            imgDataCallback(combinedData);

            if (dataSetIndex === 0) {
                markerClickHandler(handleMarkerClick);
                popupCloseHandler(handlePopupClose);
            }
            
            // const firstImgData = combinedData[0];

            // console.log(firstImgData)

            // setViewState({
            //   longitude: firstImgData.long,
            //   latitude: firstImgData.lat,
            //   zoom: 17
            // })

            // mapRef?.current.flyTo({
            //     center: [firstImgData.long, firstImgData.lat],
            //     zoom: 3.5,
            //     speed: 1.5
            // })
        });
    }, [dataSetIndex])

    const handleTransitionCallback = (data, currentData) => {
        if (currentData) {
            handlePopupClose(currentData);
        }
        handleMarkerClick(data, false, true);
    }

    const handleImageViewClose = () =>{
        handlePopupClose(imgData[originalViewImage]);
        handleMarkerClick(imgData[currentImage]);
        closeImageViewer();
    }

    const generateRightArrow = () => {
        return <span onClick={() => {
            setCurrentImage(currentImage + 1);
        }} class="styles-module_navigation__1pqAE styles-module_next__1uQwZ react-simple-image-viewer__next">❯</span>
    }

    return (
        <>
            {imgData && isViewerOpen && (
                <ImageViewer
                    src={imgData.map((data) => data.imgUrl)}
                    currentIndex={currentImage}
                    onClose={handleImageViewClose}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                    closeOnClickOutside={true}
                    rightArrowComponent={generateRightArrow()}
                />
            )}
            <Map
                mapboxAccessToken={accessToken}
                ref={mapRef}
                scrollZoom={true}
                initialViewState={viewState}
                style={{width: '100%', height: 1000}}
                mapStyle={mapStyles[1]}
            >
                {!(isMobile || isViewerOpen) && <FullscreenControl />}
                {imgData && imgData.map((imgDataObject, index) => (
                        <div>
                            {/* {popupOpen && console.log(popupOpen)}
                            {imgDataObject && console.log(imgDataObject)} */}
                            <Marker 
                                key={imgDataObject.name + '_marker'}
                                longitude={imgDataObject.long}
                                latitude={imgDataObject.lat}
                                anchor="bottom"
                                onClick={() => handleMarkerClick(imgDataObject)}
                            >
                                {/* <span style={{color: 'white'}}>{imgDataObject.name}</span> */}
                                {/* <img src={`data:image/jpeg;base64,${imgDataObject.imgBase64}`} /> */}
                            </Marker>
                            {popupOpen[imgDataObject.name] && 
                                (<Popup 
                                    key={imgDataObject.name + '_popup'}
                                    longitude={imgDataObject.long}
                                    latitude={imgDataObject.lat}
                                    onClose={() => {handlePopupClose(imgDataObject)}}
                                    closeOnClick={false}
                                    anchor="bottom"
                                    style={{width: 600, maxHeight: 400}}
                                >
                                    {(imgDataObject.imgUrl && 
                                        <img onClick={() => openImageViewer(index)} src={imgDataObject.imgUrl} style={{maxWidth: '99%', maxHeight: '99%'}}/>
                                    )}
                                </Popup>
                            )}
                        </div>
                    ))}
                {imgData && 
                    <div className={`${isViewerOpen ? 'd-none' : ''} mapboxgl-ctrl-${isMobile ? 'top-left' : 'bottom-left'}`}>
                        <div className="mapboxgl-ctrl">
                            <ImgCarousel
                                items={imgData.map((data,index)=> ({
                                    ...data,
                                    altText: data.name,
                                    caption: data.name,
                                    key: index,
                                    src: data.imgUrl || `data:image/png;base64,${data.imgBase64}`
                                }))}
                                transitionCallback={handleTransitionCallback}
                                markerSelectedCallback={(func) => {
                                    imgCarouselSwitch = func;
                                }}

                                endOfCarouselReachedHandler={setDataSetIndex}
                            />
                        </div>
                    </div>
                }
            </Map>
        </>
    );
}
